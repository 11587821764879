<template>
	<span>
		<mw-dialog v-model="dialog" v-if="tech" small title="Tech Test Results">
            <template v-slot:button>
                <v-btn @click="dialog = true" color="secondary" small>{{ completedOn }}</v-btn>
            </template>
            <template v-slot:dialog-body>
                Last tech test completed on: {{completedOn}} at {{completedAt}}: 
                <v-checkbox dense hide-details disabled label="Camera" v-model="tech.camera"></v-checkbox>
                <v-checkbox dense hide-details disabled label="Speakers" v-model="tech.speakers"></v-checkbox>
                <v-checkbox dense hide-details disabled label="Microphone" v-model="tech.mic"></v-checkbox>
            </template>
		</mw-dialog>
	</span>
</template>

<script>
import MwDialog from "@c/ui/MwDialog";

export default {
	name: "TechTestCell",
	props: ["item", "column"],
    data: () => {
        return {
            dialog: false
        }
    },
    computed: {
		user(){
			return this.$store.state.users.data[this.item.user];
		},
        tech(){
            return this.user ? this.user.tech_tested : null
        }, 
        completedOn(){
            return this.mwtimeutils.formatAsDateString(this.tech.updated_at)
        },
        completedAt(){
            return this.mwtimeutils.formatAsTimeString(this.tech.updated_at)
        }
    },
    components: {
        MwDialog
    }
};
</script>
